.cookie-agreement {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 500;
  animation: slide-left 0.4s ease;
  background: #ffffff;
  padding: 20px 0 30px 0;
  color: #ffffff;
  box-shadow: 0 0.015px 5px 0 rgba(0, 0, 0, 0.24);

  &.cookie-agreement-open {
    display: block;
    animation: slide-left 0.4s ease;
  }

  &.cookie-agreement-close {
    animation: slide-right 0.4s ease;
  }

  .cookie-agreement-holder {
    padding: 0 10px;
  }

  .row {
    margin-right: -5px;
    margin-left: -5px;
    display: flex;
  }

  .col-lg-10 {
    max-width: 83.333333%;
  }

  .col-lg-4 {
    max-width: 20%;
  }

  .col-lg-2 {
    max-width: 16.666667%;
  }

  .agreement-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    min-height: 33px;
  }

  .agreement-title {
    margin: 0 0 10px 0;
    font-size: 14px/21px;
    font-weight: bold;
    width: 100%;
    color: #222222;
    padding: 0;
  }

  .agreement-description {
    font-size: 12px;
    margin: 0;
    color: #777;

    a {
      font-size: 12px;
      color: #9AC328 !important;
    }
  }

  .cookies-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .cookies-options-container {
    display: flex;
  }

  .cookies-btn-container {
    text-align: center;
    display: flex;
    align-items: flex-end;

    a {
      line-height: 150%;
      color: #fff;
      display: block;
      padding: 10px;
      cursor: pointer;
      border-radius: 4px;
      width: 100%;
    }

  }

  #cookies-agree {
    font-size: 14px;
    border: 2px solid #222;
    box-sizing: border-box;
    margin-bottom: 10px;
    color: #222;
    max-width: 260px;
  }

  //#cookies-all {
  //  font-size: 16px;
  //  background: #9AC328;
  //  padding: 15px 10px;
  //  width: 100%;
  //}
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50px);
  }
}

@media (max-width: 1080px) {

  .cookie-agreement {

    .col-lg-10 {
      max-width: 100%;
    }

    .cookies-btn-container {
      display: flex;
      max-width: unset !important;
      justify-content: flex-end;

      a {
        margin: 0;
        margin-right: 10px;
        margin-bottom: 0 !important;
      }
    }

  }

}

@media (max-width: 991px) {
  .cookie-agreement {

    .row {
      margin-right: 0;
      margin-left: 0;
      flex-direction: column;
    }

    .col-lg-4 {
      max-width: 100%;
    }

    .col-lg-2 {
      max-width: 25%;
    }

    .cookie-agreement-container {
      padding: 0 30px;
    }

    .agreement-description {
      margin: 0 0 15px;
    }
  }
}

@media (max-width: 767px) {
  .cookie-agreement {
    padding: 10px 0 10px;

    .col-lg-2 {
      max-width: 100%;
    }

    .agreement-header {
      min-height: auto;
    }

    .agreement-title {
      margin: 0 0 5px;
      font-size: 12px;
      line-height: 1.3;
    }

    .agreement-description {
      margin: 0 0 5px;
    }

    #cookies-custom {
      padding: 5px;
      font-size: 12px;
    }

    #cookies-all {
      padding: 5px;
      font-size: 12px;
    }
  }
}
