.swal-text, .swal-footer {
  text-align: center;
}

.swal-footer {
  .swal-button-container {
    .swal-button {
      color: #ffffff;
      background-color: $color-green;
      border-color: $color-green;
      padding: rem(16) rem(40);

      @media screen and (max-width: 768px) {
        padding: rem(10) rem(25);
      }

      &:hover {
        background-color: darken($color-green, 10);
        border-color: darken($color-green, 10);
        box-shadow: none;
      }

      &:disabled {
        background-color: $color-muted !important;
        border-color: $color-muted !important;
      }
    }
  }
}
