$color-grey: #555555;
$color-l-grey: #DDDDDD;
$color-muted: #AAAAAA;
$color-green: #9AC328;
$color-danger: red;

$base-font-size: 18;
@function rem($size, $context: $base-font-size) {
  $remSize: ($size / $context) * 1rem;
  @return $remSize;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1330px
) !default;

$h1-font-size: rem(60) !default;
$h2-font-size: rem(40) !default;
$h3-font-size: rem(24) !default;
$h4-font-size: rem(18) !default;
$h5-font-size: rem(16) !default;
$h6-font-size: rem(14) !default;
