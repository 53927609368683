.header {
  background: url("../images/header_bg.jpg") center center no-repeat;
  height: rem(260);
  margin-bottom: rem(60);
}

.header__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header__title {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.header__breadcrumb {
  list-style: none;
  padding: 0;
  font-size: rem(16);
  color: $color-grey;
  margin: 0 0 rem(30) 0;

  li {
    display: inline-block;

    &:not(:last-child):after {
      content: "";
      width: rem(3.72);
      height: rem(6.26);
      display: inline-block;
      margin: 0 .2em .05em .6em;
      background: url("../images/breadcrumb_arrow.svg") center center no-repeat;
      background-size: cover;
    }

    &:last-child{
      color: $color-muted;
    }
  }
}
