@font-face {
  font-family: 'Circular XX';
  src: url('../fonts/CircularXX-Bold.woff2') format('woff2'),
  url('../fonts/CircularXX-Bold.woff') format('woff'),
  url('../fonts/CircularXX-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular XX';
  src: url('../fonts/CircularXX-Regular.woff2') format('woff2'),
  url('../fonts/CircularXX-Regular.woff') format('woff'),
  url('../fonts/CircularXX-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$font-family-primary: 'Circular XX', Arial, sans-serif;
